import React, { useState } from "react";
import DirectoryRow from "components/directory/DirectoryRow";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { DirectoryGroup } from "components/directory/types";
import { useAcceptInvite } from "components/invites/api";
import { useNavigate } from "helpers/tixxt-router";
import { formatDateFromNow } from "helpers/formatDate";
import {
  useChangeGroupSubscription,
  useChangeMembershipGroupStatus,
} from "components/groups/api";
import DirectoryActionButton from "components/directory/DirectoryActionButton";

export default function GroupRow({
  id,
  slug,
  name,
  profile_image,
  archived,
  access,
  externally_visible,
  member_count,
  activity_count,
  teaser,
  membership_status_of_current_member,
  can,
  i_haz_subscription,
  invite,
}: DirectoryGroup) {
  const navigate = useNavigate();
  const [hasSubscribed, setHasSubscribed] = useState(i_haz_subscription);
  const [memberStatus, setMemberStatus] = useState(
    membership_status_of_current_member,
  );
  const titleIcons: { icon: IconProp; title: string }[] = [];

  if (archived)
    titleIcons.push({
      icon: regular("archive"),
      title: I18n.t("js.directory.group_row.archived"),
    });
  if (access === "closed")
    titleIcons.push({
      icon: regular("lock"),
      title: I18n.t("js.directory.group_row.closed"),
    });
  if (access === "hidden")
    titleIcons.push({
      icon: regular("eye-slash"),
      title: I18n.t("js.directory.group_row.hidden"),
    });
  if (externally_visible)
    titleIcons.push({
      icon: regular("eye"),
      title: I18n.t("js.directory.group_row.externally_visible"),
    });

  const { mutate: acceptInvite } = useAcceptInvite({
    onSuccess: () => {
      navigate(`/groups/${slug}`);
    },
  });

  const { mutate: changeGroupSubscription } = useChangeGroupSubscription({
    onSuccess: (_, variables) => {
      setHasSubscribed(variables.action === "subscribe");
    },
  });

  const { mutate: changeGroupMembership } = useChangeMembershipGroupStatus({
    onSuccess: (_, variables) => {
      if (variables.action === "join") {
        can.join && navigate(`/groups/${slug}/activitystreams/${slug}`);
        can.join_moderated && setMemberStatus("in_moderation");
      } else {
        setMemberStatus("no_member");
        toastr.success(
          I18n.t("js.directory.group_row.leave.successful", {
            group_name: name,
          }),
        );
      }
    },
  });

  function getActions() {
    switch (memberStatus) {
      case "in_moderation":
        return (
          <DirectoryActionButton
            icon={{ icon: regular("adjust") }}
            text={I18n.t("js.directory.group_row.waiting_for_approval")}
            disabled
          />
        );
      case "rejected":
        return (
          <DirectoryActionButton
            icon={{ icon: regular("times") }}
            text={I18n.t("js.directory.group_row.join_rejected")}
            disabled
          />
        );
      case "member":
        return (
          <>
            {can.leave && (
              <DirectoryActionButton
                icon={{ icon: regular("right-from-bracket") }}
                text={I18n.t("js.directory.group_row.leave_group")}
                onClick={() => {
                  window.bridge?.confirm(
                    I18n.t("js.directory.group_row.leave.confirmation", {
                      group_name: name,
                    }),
                    () => changeGroupMembership({ id, action: "leave" }),
                  );
                }}
              />
            )}
            <DirectoryActionButton
              icon={{
                icon: hasSubscribed ? regular("bell-slash") : regular("bell"),
              }}
              text={
                hasSubscribed
                  ? I18n.t("js.directory.group_row.unsubscribe")
                  : I18n.t("js.directory.group_row.subscribe")
              }
              onClick={() =>
                changeGroupSubscription({
                  id,
                  action: hasSubscribed ? "unsubscribe" : "subscribe",
                })
              }
            />
          </>
        );
      case "invited":
        return (
          <DirectoryActionButton
            type="primary"
            icon={{ icon: regular("check") }}
            text={I18n.t("js.directory.group_row.accept_invitation")}
            onClick={() => acceptInvite({ inviteId: invite.id })}
          />
        );
      case "no_member":
        return (
          (can.join || can.join_moderated) && (
            <DirectoryActionButton
              type="primary"
              icon={{ icon: regular("plus") }}
              text={
                can.join
                  ? I18n.t("js.directory.group_row.join")
                  : can.join_moderated
                    ? I18n.t("js.directory.group_row.join_moderated")
                    : ""
              }
              onClick={() => changeGroupMembership({ id, action: "join" })}
            />
          )
        );
      default:
        return null;
    }
  }

  return (
    <DirectoryRow
      title={name}
      url={`/groups/${slug}`}
      imageUrl={profile_image}
      fallbackIcon={regular("users")}
      titleIcons={titleIcons}
      actions={getActions()}
    >
      <p className="text-muted text-sm">
        {I18n.t("js.directory.group_row.member_count", { count: member_count })}
        {", "}
        {I18n.t("js.directory.group_row.activity", { count: activity_count })}
      </p>
      {membership_status_of_current_member === "invited" && (
        <p>
          {I18n.t("js.directory.group_row.invited_by", {
            invitor: invite.author.name,
            time_ago: formatDateFromNow(new Date(invite.created_at)),
          })}
        </p>
      )}
      {teaser && (
        <p className="text-sm whitespace-pre-line max-w-4xl">{teaser}</p>
      )}
    </DirectoryRow>
  );
}

import { useMutation, UseMutationOptions, useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import queryClient from "helpers/queryClient";

type ChannelPayload = {
  id: string;
  slug: string;
  title: string;
  description: string;
  image: {
    id: string;
    name: string;
    content_type: string;
    image_url: string;
    small_thumb: string;
    large_thumb: string;
    size: number;
    extension: string;
    type: string;
  } | null;
  status: string;
  i: {
    can: { [rule: string]: boolean | undefined };
    subscribed: boolean;
    favorited: boolean;
  };
  blog_id: string;
};

export function useChannels() {
  return useQuery<{
    subscribed: ChannelPayload[];
    recent: ChannelPayload[];
    popular: ChannelPayload[];
  }>("/channels", {
    refetchOnWindowFocus: false,
    initialData: { subscribed: [], recent: [], popular: [] },
  });
}

export function useChannel(slug: string | undefined) {
  return useQuery<ChannelPayload>(`/channels/${slug}`, {
    enabled: !!slug,
    refetchOnWindowFocus: false,
  });
}

export function updateChannelFavorited(slug, favorited) {
  const channel = queryClient.getQueryData<ChannelPayload>(`/channels/${slug}`);
  if (!channel) return;

  queryClient.setQueryData(`/channels/${slug}`, {
    ...channel,
    i: { ...channel.i, favorited },
  });
}

type WidgetPayload = {
  type: string;
  name: string;
  title: string;
  title_background_override: null | string;
};

export const useWidget = ({
  channelId,
  widgetId,
}: {
  channelId: string;
  widgetId?: string;
}) =>
  useQuery<WidgetPayload>(`/channels/${channelId}/widgets/${widgetId}`, {
    refetchOnWindowFocus: false,
    enabled: !!widgetId,
  });

export function persistWidget(
  channelId: string,
  widgetId: string | undefined,
  body: WidgetPayload,
) {
  return fetchApi(
    `/channels/${channelId}/widgets${widgetId ? `/${widgetId}` : ""}`,
    { method: widgetId ? "PUT" : "POST", body },
  );
}
export const usePersistWidget = (
  {
    channelId,
    widgetId,
  }: {
    channelId: string;
    widgetId?: string;
  },
  options?: Omit<
    UseMutationOptions<any, { message: string }, WidgetPayload, unknown>,
    "mutationFn"
  >,
) =>
  useMutation(
    (body: WidgetPayload) => persistWidget(channelId, widgetId, body),
    options,
  );

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";
import { Filter } from "components/directory/types";
import DirectoryToolbar from "components/directory/DirectoryToolbar";
import { setSearchParam } from "helpers/tixxt-router";

type ToolBarArgs = {
  isLoading: boolean;
  filter: Filter;
};

export default function ToolBar({ isLoading, filter }: ToolBarArgs) {
  return (
    <DirectoryToolbar
      isLoading={isLoading}
      filter={filter}
      searchField={I18n.t("js.directory.channel_tab")}
      showFilterInitially
      actions={
        <div className="flex gap-2">
          {Preload.current_member?.can.create_channels && (
            <a
              className="btn btn-primary sm:ml-2 flex gap-1 items-center"
              href="/channels/new"
              title={I18n.t("js.directory.headline_tools.create_channel")}
            >
              <FontAwesomeIcon icon={regular("circle-plus")} />
              {I18n.t("js.directory.headline_tools.create_channel")}
            </a>
          )}
        </div>
      }
    >
      <div className="flex flex-row gap-2 mt-4">
        <div className="search-subscribed">
          <label className="checkbox">
            <input
              name="only_subscriptions"
              type="checkbox"
              checked={filter.only_subscribed === "true" || false}
              onChange={(e) => {
                e.preventDefault();
                setSearchParam(
                  "only_subscribed",
                  filter.only_subscribed === "true" ? "" : "true",
                );
                setSearchParam("page", "");
              }}
              disabled={isLoading}
            />
            {I18n.t("js.directory.search.channel_subscriptions")}
          </label>
        </div>
      </div>
    </DirectoryToolbar>
  );
}

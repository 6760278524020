import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function PageLoading() {
  return (
    <div className="flex gap-2 items-center">
      <FontAwesomeIcon icon={regular("spinner")} spin />
      {I18n.t("js.loading")}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import classNames from "classnames";
import { isEmpty, omit } from "lodash";
import Glossary from "components/shared/Glossary";
import { setSearchParam } from "helpers/tixxt-router";
import { Filter } from "components/directory/types";

type DirectoryToolbarArgs = {
  isLoading: boolean;
  filter: Filter;
  searchField: string;
  actions?: React.ReactNode;
  showFilterInitially?: boolean;
  children?: React.ReactNode;
};

export default function DirectoryToolbar({
  isLoading,
  filter,
  actions,
  searchField,
  showFilterInitially = false,
  children,
}: DirectoryToolbarArgs) {
  const hasActiveFilters = !isEmpty(omit(filter, ["q", "page"]));
  const [showFilter, setShowFilter] = useState(
    showFilterInitially || hasActiveFilters,
  );
  const [queryString, setQueryString] = useState(filter.q || "");
  const [searchedString, setSearchedString] = useState(filter.q || "");
  const [selectedLetter, selectLetter] = useState(filter.letter || "");

  useEffect(() => {
    if (hasActiveFilters) {
      setShowFilter(showFilterInitially || hasActiveFilters);
    }
  }, [hasActiveFilters]);

  function onSearch(e?: any) {
    e.preventDefault();

    if (!isEmpty(queryString)) {
      selectLetter("");
    }

    setSearchedString(queryString);
  }

  useEffect(() => {
    setSearchParam("q", searchedString);
    setSearchParam("page", "");
  }, [searchedString]);

  useEffect(() => {
    setSearchParam("letter", selectedLetter);
    setSearchParam("page", "");
  }, [selectedLetter]);

  // reset q and letter filters for navigating between group tabs
  useEffect(() => {
    setQueryString(filter.q || "");
    selectLetter(filter.letter || "");
  }, [filter]);

  return (
    <div className="border-box mt-6 p-3">
      <div className="flex flex-col gap-1 md:gap-0 md:flex-row md:divide-x md:divide-neutral">
        <div className="grow input-group md:mr-2 w-auto">
          <input
            type="text"
            placeholder={I18n.t("js.directory.search.in", {
              field: searchField,
            })}
            value={queryString}
            onChange={(e) => setQueryString(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && onSearch(e)}
            disabled={isLoading}
          />
          <button
            className="btn btn-primary"
            onClick={onSearch}
            disabled={isLoading}
          >
            <FontAwesomeIcon icon={regular("magnifying-glass")} />
          </button>
        </div>
        <div className="flex flex-wrap gap-1 justify-end sm:flex-nowrap sm:divide-x sm:divide-neutral sm:gap-0">
          <div>
            <button
              className={classNames("btn btn-light md:mx-2", {
                active: showFilter,
              })}
              onClick={(e) => {
                e.preventDefault();
                setShowFilter(!showFilter);
              }}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={regular("filter")} />
            </button>
          </div>
          {actions && actions}
        </div>
      </div>
      {showFilter && (
        <div className="search-filter-bar">
          <div className="flex flex-col space-y-4">
            <Glossary
              onSelect={(letter: string) => {
                setQueryString("");
                setSearchedString("");
                selectLetter(letter);
              }}
              selectedLetter={selectedLetter}
            />
          </div>
          {children}
        </div>
      )}
    </div>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import React from "react";
import DirectoryToolbar from "components/directory/DirectoryToolbar";
import { Filter } from "components/directory/types";
import ReactSelect from "react-select";
import Label from "components/shared/form/Label";
import { setSearchParam } from "helpers/tixxt-router";
import { find, toString } from "lodash";

type SelectOption = {
  label: string;
  value: string;
};

type ToolBarArgs = {
  isLoading: boolean;
  filter: Filter;
  categoryName: string;
  categorySlug: string;
  categoryPluralName: string;
};

export default function ToolBar({
  isLoading,
  filter,
  categoryName,
  categorySlug,
  categoryPluralName,
}: ToolBarArgs) {
  const selectOptions: SelectOption[] = [
    {
      label: I18n.t("js.directory.search.group.fields.name"),
      value: "name",
    },
    {
      label: I18n.t("js.directory.search.group.fields.name_and_profile"),
      value: "name_and_profile",
    },
  ];

  const defaultValue: SelectOption = filter.search_in
    ? find(selectOptions, { value: toString(filter.search_in) }) ||
      selectOptions[0]
    : selectOptions[0];

  return (
    <DirectoryToolbar
      isLoading={isLoading}
      filter={filter}
      searchField={categoryPluralName}
      actions={
        <div className="flex gap-2">
          <a
            className="btn btn-primary sm:ml-2 flex gap-1 items-center"
            href={`/groups/new/${categorySlug}`}
            title={I18n.t("js.directory.headline_tools.create_channel")}
          >
            <FontAwesomeIcon icon={regular("circle-plus")} />
            {I18n.t("js.directory.headline_tools.create_group", {
              category_group_name: categoryName,
            })}
          </a>
        </div>
      }
    >
      <div className="form-horizontal my-6">
        <div className="controls">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={filter.archived === "true"}
              onChange={(e) => {
                e.preventDefault();
                setSearchParam(
                  "archived",
                  filter.archived === "true" ? "" : "true",
                );
                setSearchParam("page", "");
              }}
            />
            {I18n.t("js.directory.search.archive")}
          </label>
        </div>
        <div className="control-group">
          <Label label={I18n.t("js.directory.search.in_fields")} />
          <div className="controls remove-input-txt-border">
            <ReactSelect
              closeMenuOnSelect
              options={selectOptions}
              className="form-select-container"
              classNamePrefix="form-select"
              defaultValue={defaultValue}
              isDisabled={isLoading}
              onChange={(option) => {
                option &&
                  setSearchParam(
                    "search_in",
                    option.value === "name" ? "" : option.value,
                  );
                setSearchParam("page", "");
              }}
              unstyled
            />
          </div>
        </div>
      </div>
    </DirectoryToolbar>
  );
}

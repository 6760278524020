import React from "react";
import DirectoryRow from "components/directory/DirectoryRow";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { map } from "lodash";
import { DirectoryMembership } from "components/directory/types";
import DirectoryRowBadge from "components/directory/DirectoryRowBadge";

export default function MemberRow({
  id,
  displayName,
  images,
  profile,
  is_external,
  is_network_admin,
  roles,
}: DirectoryMembership) {
  return (
    <DirectoryRow
      title={displayName}
      url={`/members/${id}`}
      imageUrl={images.large}
      fallbackIcon={regular("user")}
      badges={
        <>
          {is_network_admin && (
            <DirectoryRowBadge
              tooltipTitle={I18n.t("js.directory.member_row.administrator")}
              title={I18n.t("js.directory.member_row.administrator_short")}
              icon={regular("user-circle")}
              className="bg-primary/50"
            />
          )}
          {is_external && (
            <DirectoryRowBadge
              tooltipTitle={I18n.t("js.directory.member_row.external")}
              title={I18n.t("js.external_member.badge")}
              className="text-muted bg-gray-100"
            />
          )}
          {map(roles, (role) => (
            <DirectoryRowBadge
              key={role.name}
              tooltipTitle={role.name}
              title={role.name}
              style={{
                backgroundColor: role.color || "var(--gray-500)",
                color: role.text_color || "white",
              }}
            />
          ))}
        </>
      }
    >
      {map(
        profile,
        (value, field) =>
          value && (
            <p key={field} className="text-sm">
              {value}
            </p>
          ),
      )}
    </DirectoryRow>
  );
}
